import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Bratwurst Style Plant Based Sausage</title>
          <meta
            name="description"
            content="Our Raised & Rooted™ Bratwurst Style Plant Based Sausage has all the juice and sizzle of regular brats but is made with plant protein. Try some today!"
          />
          <meta itemProp="name" content="Bratwurst Style Plant Based Sausage" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Bratwurst Style Plant Based Sausage"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="Now here’s a plant based sausage worth firing up the grill for. Made with 100% plant protein and loaded with big, juicy flavor, your guests will be sure to remember your next cookout."
              itemColor="#006B2D"
              valueText1="17"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="1"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="75"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="Made with Plant Based Protein"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Compared to USDA for Bratwurst, Pork, Beef Link"
              footnoteColor="#006B2D"
              productID="00053400000330"
            >
              <img src="/raised-and-rooted-bratwurst-style-plant-based-sausage-708x721.png" alt="Raised And Rooted Bratwurst Style Plant Based Sausage" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-bratwurst-style-plant-based-sausage-708x721-ls.png"
              altText="Raised And Rooted Bratwurst Style Plant Based Sausage"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Our Raised & Rooted™ Bratwurst Style Plant Based Sausage is made with pea protein and loaded with tailgate-ready taste."
            >
              <h4>NUTRITION</h4>
              <p>
              17 grams of protein per serving, 75% less saturated fat*, and soy free.
              </p>
              <p>
              *compared to USDA Italian sausage, pork.
              </p>
              <h4>TASTE</h4>
              <p>
              Finally, a plant based brat that tastes meaty enough for your next backyard BBQ or tailgate. 
              You can serve this juicy, bratwurst style sausage on its own or on a warm, toasty bun lined with dijon and sauerkraut. 
              We couldn’t believe it was this good either.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>SKILLET</b>
                  <p>
                  1.Remove sausage from packaging and place in a greased non-stick skillet.
                  </p>
                  <p>
                  2. Cook over medium heat for 10-12 minutes or until sausage is thoroughly cooked*, turning occasionally.
                  </p>
                  <b>GRILL</b>
                  <p>
                  1. Preheat greased grill on medium heat. Remove sausage from packaging and place on preheated grill.
                  </p>
                  <p>
                  2. Grill for 10-12 minutes or until thoroughly cooked*, turning occasionally.
                  </p>
                  <br>
                  </br>
                  <p>
                  Note: Sausage must reach a minimum of 165°F as measured with an instant read thermometer in several places. 
                  *When fully cooked, the sausage should still be a reddish pink color.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>4 Servings Per Container</p>
                  <p>Serving Size 1 link (98g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 230</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 15g</td>
                      <td>19%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1g</td>
                      <td>5%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 4.5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 8g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 580mg</td>
                      <td>25%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 7g</td>
                      <td>3%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 1g</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 2g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 2g Added Sugars</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td>Protein 17g</td>
                      <td>24%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0mcg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Calcium 140mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Iron 3.8mg</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td>Potassium 730mg</td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *The % Daily Value tells you how much a nutrient in a serving of food contributes to a daily diet. 
                        2,000 calores a day is used for general nutritionn advice.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                WATER, TEXTURED PEA PROTEIN [PEA PROTEIN ISOLATE, TAPIOCA
                STARCH, VITAMIN E (MIXED TOCOPHEROLS)], CANOLA OIL, PEA PROTEIN ISOLATE, CONTAINS
                2% OR LESS: DEXTROSE, CALCIUM CHLORIDE, CALCIUM SULPHATE DIHYDRATE, CITRIC
                ACID, FRUIT JUICE COLOR, GARLIC POWDER, GLYCERINE, MALTODEXTRIN, MODIFIED
                CELLULOSE, MODIFIED CORN STARCH, NATURAL FLAVOR, NATURAL FLAVOR BEEF TYPE [NATURAL FLAVOR,
                SALT, YEAST EXTRACT, MALTODEXTRIN (FROM CORN)], NATURAL FLAVOR LARD TYPE
                (SUNFLOWER OIL, NATURAL FLAVOR), NATURAL PORK TYPE FLAVOR (NATURAL FLAVOR,
                SALT, XANTHAN GUM), ONION POWDER, POTASSIUM CHLORIDE, POTATO STARCH, PROPYLENE
                GLYCOL, ROSEMARY EXTRACT, SALT, SEA SALT, SODIUM ALGINATE, SOYBEAN OIL, SPICE,
                SUNFLOWER OIL, TETRASODIUM PYROPHOSPHATE, VEGETABLE JUICE COLOR, VINEGAR, IN A
                CALCIUM ALGINATE CASING.

                </p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000330" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
